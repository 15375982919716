import { Loading } from "element-ui";
// 验证旧密码
import consumer_user_checkPassword from "@/lib/data-service/haolv-default/consumer_user_checkPassword";

// 修改密码
import consumer_user_updatePassword from "@/lib/data-service/haolv-default/consumer_user_updatePassword";

export default {
  data() {
    const regNum = /[0-9]/;
    const regLetter = /[a-zA-Z]/;

    const validateOldPassword = (rule, value, callback) => {
      if (value) {
        if (value.length >= 6) {
          consumer_user_checkPassword({oldPassword: value}).then((result) => {
            callback();
          }).catch((err) => {
            callback(new Error(err.data.msg));
          });
        } else {
          callback(new Error("密码至少6位"));
        }
      } else {
        callback(new Error("请输入旧密码"));
      }
    };

    const validateNewPassword = (rule, value, callback) => {
      if (value) {
        if (value.length >= 6) {
          const oldPassword = this.form.oldPassword;
          const affirmPassword = this.form.ffirmPassword;
          if (value === oldPassword) {
            callback(new Error("新密码不能与旧密码相同"));
          } else if (value !== affirmPassword && affirmPassword) {
            callback(new Error("两次密码输入不一致"));
          } else {
            if (regNum.test(value) && regLetter.test(value)) {
              callback();
            } else {
              callback(new Error("密码需数字与字母组合"));
            }
          }
        } else {
          callback(new Error("密码至少6位"));
        }
      } else {
        callback(new Error("请输入新密码"));
      }
    };

    const validateAffirmPassword = (rule, value, callback) => {
      if (value) {
        if (value.length >= 6) {
          const oldPassword = this.form.oldPassword;
          const newPassword = this.form.newPassword;
          if (value === oldPassword) {
            callback(new Error("新密码不能与旧密码相同"));
          } else if (value !== newPassword && newPassword) {
            callback(new Error("两次密码输入不一致"));
          } else {
            if (regNum.test(value) && regLetter.test(value)) {
              callback();
            } else {
              callback(new Error("密码需数字与字母组合"));
            }
          }
        } else {
          callback(new Error("密码至少6位"));
        }
      } else {
        callback(new Error("请再次确认密码"));
      }
    };

    return {
      loadingInstance: null,
      form: {
        oldPassword: "",
        newPassword: "",
        affirmPassword: "",
      },

      rules: {
        oldPassword: [
          // { required: true, validator: validateOldPassword, trigger: "blur" },
          { required: true, message: "请输入旧密码", trigger: "blur" },
          // { min: 6, message: "密码至少6位", trigger: "blur" },
          // {
          //   pattern: /[a-zA-Z0-9]/,
          //   message: "密码格式不正确",
          //   trigger: "blur",
          // },
        ],
        newPassword: [
          { required: true, validator: validateNewPassword, trigger: "blur" },
        ],
        affirmPassword: [
          {
            required: true,
            validator: validateAffirmPassword,
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {},
  components: {},
  methods: {
    input_password(key) {
      const form = this.form;
      form[key] = form[key].replace(/[^a-zA-Z0-9]/g, "");
    },
    commit_change() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loadingInstance = Loading.service({ text: "请求中..." });
          consumer_user_updatePassword(this.form)
            .then((result) => {
              for (let i = 0; i < 3; i++) {
                this.$refs["password" + (i + 1)].passwordVisible = false;
              }
              this.loadingInstance.close();
              this.$message.success("密码修改成功");
            })
            .catch((err) => {
              this.loadingInstance.close();
            });
        } else {
          return false;
        }
      });
    },
  },
  created() {},
  mounted() {},
  activated() {},
  deactivated() {},
  destroyed() {},
  watch: {},
  filters: {},
};
