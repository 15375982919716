/*
*修改密码  http://219.128.52.2:18765/doc.html#/haolv-biz/t-us-user-controller/updatePasswordUsingPOST
*/
const __request = require(`./__request/__request_contentType_json`)
const consumer_user_checkPassword = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/user/checkPassword',
        data: data,
        enable_error_alert: false,
    }
    return __request(pParameter)
}
export default consumer_user_checkPassword;